/*eslint-disable*/ export const messages = {
    '25': '25',
    '50': '50',
    '100': '100',
    '250': '250',
    '500': '500',
    "'expanded closed": "'expandido cerrado",
    "'expanded open": "'expandido abierto",
    '(all versions)': '(todas las versiones)',
    '0 results found when searching for "{search}"': ['0 resultados al buscar "', ['search'], '"'],
    '<0/> — <1>{title}</1>': ['<0/> — <1>', ['title'], '</1>'],
    '<0/>Prev': '<0/>Prev.',
    '<0>-- for sale</0> from --': '<0>-- en venta</0> desde --',
    '<0><1/> Add Release</0>': '<0><1/> Añadir edición</0>',
    '<0>Add Release</0>': '<0>Añada edición</0>',
    '<0>Click here</0> if you would like to view it in {from} instead of your default language ({to}).': [
        '<0>Haz clic aquí</0> si quieres verlo en ',
        ['from'],
        ' en vez de en tu idioma predeterminado (',
        ['to'],
        ').',
    ],
    '<0>Create Master Release</0>': '<0>Generar Master-Release</0>',
    '<0>Credits</0>': '<0>Créditos</0>',
    '<0>DO NOT USE</0> Please use <1>{0}</1> instead.': ['<0>NO USE</0>Por favor use<1>', ['0'], '</1>en su lugar.'],
    '<0>Learn more about selling on Discogs<1/></0>': '<0>Más información acerca de cómo vender en Discogs<1/></0>',
    '<0>Showing 0 - 0 of 0</0>': '<0>Mostrando 0-0 de 0</0>',
    '<0>{0} for sale</0> from <1/>': ['<0>', ['0'], 'en venta</0> desde <1/>'],
    '<0>{0}</0>': ['<0>', ['0'], '</0>'],
    '<0>{creditName}</0>': ['<0>', ['creditName'], '</0>'],
    '<0>{name}</0> by <1>{username}</1>': ['<0>', ['name'], '</0> de <1>', ['username'], '</1>'],
    '<0>{submissionsPending} submissions pending</0>': ['<0>', ['submissionsPending'], 'envíos Pendientes</0>'],
    API: 'API',
    ASIN: 'ASIN',
    'About Discogs': 'Sobre Discogs',
    'Accessibility Statement': 'Declaración de accesibilidad',
    Actions: 'Acciones',
    Ad: 'Anuncio',
    Add: 'Añadir',
    'Add An Image': 'Añadir una imagen',
    'Add Artist to List': 'Añada artista a lista',
    'Add Field': 'Add Field',
    'Add Folder': 'Add Folder',
    'Add List To Dashboard': 'Añadir lista al escritorio',
    'Add Master to List': 'Añada Master a lista',
    'Add Release': 'Añadir Publicación',
    'Add Release to List': 'Añada edición a lista',
    'Add Review': 'Añadir reseña',
    'Add Selected Release to Collection': 'Añada edición seleccionada a colección',
    'Add Selected Release to Inventory': 'Añada edición seleccionada a inventario',
    'Add Selected Release to List': 'Añada edición seleccionada a lista',
    'Add Selected Release to Wantlist': 'Añada edición seleccionada a lista de deseos',
    'Add To Collection': 'Añadir a la colección',
    'Add To Inventory': 'Añadir al inventario',
    'Add To Wantlist': 'Añadir a la lista de deseos',
    'Add all versions to Wantlist': 'Añada todas las versiones a lista de deseos',
    'Add description...': 'Añadir descripción...',
    'Add friend': 'Añadir amigo',
    'Add notes...': 'Añadir notas...',
    'Add to Collection': 'Agregar a la Colección',
    'Add to List': 'Añadir a la Lista',
    'Add to Wantlist': 'Añadir a deseados',
    'Add to wantlist': 'Añadir a la lista de deseos',
    'Add video': 'Añadir vídeo',
    'Add {0} results to Wantlist': ['Añada ', ['0'], ' resultados a lista de deseos'],
    Added: 'Añadido',
    'Added folder': 'Carpeta añadida',
    'Added just now': 'Añadido recientemente',
    'Added video': 'Vídeo añadido',
    'Advanced Search': 'Búsqueda Avanzada',
    'Advertise With Us': 'Anúnciate con nosotros',
    Advertisement: 'Publicidad',
    Aliases: 'Apodos',
    All: 'Todo',
    'All Items': 'Todos los artículos',
    'All Lists': 'Todas las listas',
    'All Media': 'Todos los medios',
    'All folders ({0})': ['All folders (', ['0'], ')'],
    'An error occurred when loading the videos.': 'Se ha producido un error al cargar los vídeos.',
    Applications: 'Aplicaciones',
    'Applying filters': 'Aplicando filtros',
    'Are you sure you want to delete this field: "{0}"?': ['¿Seguro que deseas eliminar el campo "', ['0'], '"?'],
    'Are you sure you want to delete this folder: "{0}"?': ['¿Seguro que deseas eliminar la carpeta "', ['0'], '"?'],
    'Are you sure you want to remove these items?': '¿Estás seguro de que quieres eliminar estos artículos?',
    'Are you sure you want to remove this item?': '¿Estás seguro de que quieres eliminar este artículo?',
    Artist: 'Artista',
    'Artist (A-Z)': 'Artista (A-Z)',
    'Artist (Z-A)': 'Artista (Z-A)',
    Artists: 'Artistas',
    'At least 10 words must be entered. Please enter at least {missingWords} more.': [
        'Hay que introducir 10 palabras como mínimo. Añade al menos ',
        ['missingWords'],
        ' palabras más.',
    ],
    Audio: 'Audio',
    'Avg Rating': 'Valoración media',
    'Bad gateway.': 'Puerta de enlace incorrecta.',
    'Bad request.': 'Solicitud incorrecta.',
    Barcode: 'Código de Barras',
    'Barcode and Other Identifiers': 'Código de Barras y Otros Identificadores',
    'Based on last 30 sales': 'Según las últimas 30 ventas',
    'Betacam-SP': 'Betacam-SP',
    Billing: 'Facturación',
    'Blu-ray': 'Blu-ray',
    'Bottom pagination': 'Paginación inferior',
    'Box Set': 'Cofre',
    'Buy Music': 'Comprar música',
    Buyer: 'Comprador',
    By: 'Por',
    CD: 'CD',
    CDr: 'CDr',
    'California Privacy Notice': 'Aviso de confidencialidad de California',
    Cancel: 'Cancelar',
    Careers: 'Ofertas de empleo',
    Cart: 'Carrito',
    Cassette: 'Cassette',
    'Cat#': 'Cat. nº',
    'Catalog Number': 'Número de catálogo',
    'Catalog Number (0-9)': 'Número de catálogo (0-9)',
    'Catalog Number (9-0)': 'Número de catálogo (9-0)',
    Categories: 'Categorías',
    Choices: 'Selecciones',
    'Clear All': 'Elimine todo',
    'Clear Selection': 'Elimine la selección',
    'Clear all': 'Borrar todo',
    Close: 'Cerrar',
    'Close Versions': 'Cerrar versiones',
    'Collapse row': 'Contraer fila',
    'Collapse section': 'Cierre sección',
    Collected: 'Coleccionados',
    Collection: 'Colección',
    'Collection Items': 'Artículos de la colección',
    'Collection Settings': 'Configuración de la colección',
    'Collection bottom pagination': 'Paginación inferior de la colección',
    Comments: 'Comments',
    'Comments on this item': 'Comentarios sobre este artículo',
    Community: 'Comunidad',
    'Community Guidelines': 'Normas de la Comunidad',
    'Companies, etc.': 'Compañías, etc.',
    'Complete and correct': 'Completo y correcto',
    'Conflict.': 'Conflicto.',
    'Contact Info': 'Información de contacto',
    Contribute: 'Contribuye',
    Contributed: 'Contribución realizada',
    'Contributor List': 'Lista de contribuyentes',
    Contributors: 'Contribuyentes',
    'Cookie Policy': 'Normas sobre cookies.',
    'Copy {0} Code': ['Copiar código ', ['0']],
    'Copyright ©': 'Copyright ©',
    Correct: 'Corregir',
    'Could not add Collection note field': 'No se pudo añadir un campo para notas de la colección',
    'Could not add folder': 'No se pudo añadir una carpeta',
    'Could not add release to collection': 'No se ha podido añadir la edición a la colección',
    'Could not add release to list': 'No se ha podido añadir la edición a la lista',
    'Could not add release to wantlist': 'No se ha podido añadir la edición a la lista de deseos',
    'Could not change your language preference': 'No se ha podido cambiar el idioma de preferencia',
    'Could not delete folder': 'No se pudo eliminar la carpeta',
    'Could not edit release videos': 'No se han podido editar los vídeos de la edición',
    'Could not edit the collection note': 'No se ha podido editar la nota de la colección',
    'Could not edit the list': 'No se pudo editar la lista',
    'Could not edit the list item': 'No se pudo editar el artículo de la lista',
    'Could not edit videos': 'No se han podido editar los vídeos',
    'Could not edit wantlist note': 'No se ha podido editar la nota de la lista de deseos',
    'Could not mark review as helpful': 'No se ha podido marcar la crítica como útil',
    'Could not move collection item': 'No se ha podido mover el artículo de la colección',
    'Could not remove Collection note field': 'No se pudo eliminar el campo para notas de la colección',
    'Could not remove collection note': 'No se ha podido eliminar la nota de la colección',
    'Could not remove list': 'No se pudo eliminar la lista',
    'Could not remove release from wantlist': 'No se ha podido eliminar la edición de la lista de deseos',
    'Could not remove review': 'No se pudo eliminar la revisión',
    'Could not remove the list item': 'No se pudo eliminar el artículo de la lista',
    'Could not remove the release from your collection': 'No se pudo eliminar la edición de su colección',
    'Could not rename folder': 'No se pudo renombrar la carpeta',
    'Could not save changes to Collection note field':
        'No se pudo guardar los cambios en el campo para notas de la colección',
    'Could not update dashboard': 'No se pudo actualizar el panel',
    Count: 'Contar',
    Countries: 'Países',
    Country: 'País',
    'Cover of {0}, {1}, {2}': ['Carátula de ', ['0'], ', ', ['1'], ', ', ['2']],
    'Cover of {title}': ['Portada de ', ['title']],
    Created: 'Creado',
    Credits: 'Créditos',
    'Credits ({0})': ['Créditos (', ['0'], ')'],
    'Current Videos': 'Videos actuales',
    DVD: 'DVD',
    Dashboard: 'Escritorio',
    'Data Correct': 'Información correcta',
    'Data Quality': 'Calidad de la información',
    'Data quality rating:': 'Calificación de la calidad de la información',
    'Database Guidelines': 'Normas de la base de datos',
    'Date added': 'Fecha añadida',
    Delete: 'Eliminar',
    'Delete Field': 'Eliminar campo',
    'Delete List': 'Lista de eliminados',
    'Delete Review': 'Eliminar crítica',
    'Delete This List': 'Eliminar esta lista',
    'Delete forever': 'Delete forever',
    'Depósito Legal': 'Depósito Legal',
    Description: 'Descripción',
    'Designed At': 'Diseñado en',
    Developers: 'Desarrolladores',
    'Did you mean the {0} named {1}': ['¿Es tal vez ', ['0'], ', cuyo nombre es ', ['1'], '?'],
    'Digital content is not available for sale or trade.': 'No es posible vender ni comprar contenidos digitales.',
    Disco: 'Música disco',
    'Discogs Shipping': 'Envío de Discogs',
    'Discogs is not responsible for the accuracy, legality, or content of external sites or for that of subsequent links.':
        'Discogs is not responsible for the accuracy, legality, or content of external sites or for that of subsequent links.',
    'Discogs offensive marketplace item policies': 'Discogs offensive marketplace item policies',
    Discover: 'Descubrir',
    'Distributed By': 'Distribuido por',
    'Distribution Code': 'Código de distribución',
    'Do you really want to remove this list?': '¿Realmente desea eliminar esta lista?',
    'Do you really want to remove this review?': '¿Seguro que quieres eliminar esta crítica?',
    'Download now from Google Play': 'Descárgalo ahora de Google Play',
    'Download now from the App Store': 'Descarga ahora de la App Store',
    Draft: 'Borrador',
    Drafts: 'Borradores',
    Dropdown: 'Dropdown',
    'Duplicated By': 'Duplicado por',
    Edit: 'Editar',
    'Edit Artist': 'Editar artista',
    'Edit Images': 'Editar imágenes',
    'Edit Label': 'Editar sello discográfico',
    'Edit Master Release': 'Editar edición maestra',
    'Edit Notes': 'Notas de edición',
    'Edit Release': 'Editar publicación',
    'Edit collection note': 'Edit collection note',
    'Edited At': 'Editado en',
    'Edited just now': 'Editado justo ahora',
    'Editing Videos for <0>{name}</0>': ['Editar vídeos de <0>', ['name'], '</0>'],
    Electronic: 'Electrónica',
    'Enable Keyboard Shortcuts:': 'Habilitar teclas de acceso rápido:',
    'Engineered At': 'Grabado en',
    English: 'Inglés',
    'Enter your comment': 'Introduce tu comentario',
    'Enter your reply': 'Introduce tu respuesta',
    'Entirely Incorrect': 'Totalmente incorrecto',
    Error: 'Error',
    'Error fetching random item': 'Error al obtener un artículo aleatorio',
    'Estimated Collection Value': 'Estimated Collection Value',
    Everything: 'Todo',
    'Exclusive Retailer': 'Vendedor exclusivo',
    'Existing List': 'Lista actual',
    'Expand row': 'Ampliar fila',
    'Expand section': 'Extienda sección',
    'Expectation failed.': 'Error de expectativa.',
    Experimental: 'Música experimental',
    Explore: 'Explorar',
    'Explore All': 'Explorar todo',
    'Explore lists from the Discogs community. Lists can be about anything–notable album covers, prolific producers, your favorite holiday albums. The possibilities are endless! Lists can contain artists, releases, labels, or even other lists.':
        'Echa un vistazo a las listas de la comunidad de Discogs. Puede haber listas de todo tipo: portadas de álbumes de gran calidad, productores prolíficos, álbumes más populares para la Navidad, etc. ¡Las posibilidades son infinitas! Las listas pueden girar en torno a artistas, ediciones, sellos discográficos o incluso otras listas.',
    "Explore music from the {name} label. Discover what's missing in your collection and shop for {name} releases.": [
        'Explore música del sello discográfico',
        ['name'],
        '. Descubra lo que falta en su colección y adquiera ejemplares ',
        ['name'],
        '.',
    ],
    'Explore songs, recommendations, and other album details for {title} by {0}. Compare different versions and buy them all on Discogs.':
        [
            'Explore canciones, recomendaciones y otros detalles del álbum por ',
            ['title'],
            ' por ',
            ['0'],
            '. Compare diferentes versiones y cómprelas todas en Discogs.',
        ],
    'Explore the discography of {name}. Shop for vinyl, CDs, and more from {name} on Discogs.': [
        'Explore la discografía de ',
        ['name'],
        '. Compre vinilo, CDs y mas de ',
        ['name'],
        'en Discogs.',
    ],
    'Explore the tracklist, credits, statistics, and more for {title} by {0}. Compare versions and buy on Discogs': [
        'Explore la lista de canciones, créditos, estadísticas y más por ',
        ['title'],
        ' por ',
        ['0'],
        '. Compare versiones y compre en Discogs',
    ],
    "Explore {name}'s biography, discography, and artist credits. Shop rare vinyl records, top albums, and more on Discogs.":
        [
            'Explore ',
            ['name'],
            "'s biography, discography, and artist credits. Shop rare vinyl records, top albums, and more on Discogs.",
        ],
    Export: 'Exportar',
    'Export Collection': 'Exportar colección',
    'Exported By': 'Exportado por',
    Facebook: 'Facebook',
    'Failed dependency.': 'Dependencia errónea.',
    'Field Name': 'Nombre del campo',
    File: 'Archivo',
    'Filmed At': 'Filmado en',
    'Filter by': 'Filtrado por',
    Filters: 'Filtros',
    'Find a country': 'Elija un país',
    'Find a format': 'Elija un formato',
    'Find a label or company': 'Encuentre un sello o compañía',
    'Find a year': 'Elija un año',
    'Focus on Search': 'Concéntrarse en Buscar',
    Folder: 'Carpeta',
    'Folder Name': 'Folder Name',
    'Folder deleted': 'Carpeta eliminada',
    'Folder renamed': 'Carpeta renombrada',
    'Folk, World, & Country': 'Folk, músicas del mundo y country',
    'Follow Us': 'Síguenos',
    Footer: 'Pie de página',
    'For Sale': 'En venta',
    'For sale on Discogs': 'En venta en Discogs',
    'Forbidden.': 'Prohibido.',
    Format: 'Formato',
    'Format (A-Z)': 'Formato (A-Z)',
    'Format (Z-A)': 'Formato (Z-A)',
    Formats: 'Formatos',
    Forum: 'Foro',
    French: 'Francés',
    Friends: 'Amigos',
    From: 'De',
    'Funk / Soul': 'Funk/soul',
    'Gateway timeout.': 'Se agotó el tiempo de espera de la puerta de enlace.',
    Genre: 'Género',
    Genres: 'Géneros',
    German: 'Alemán',
    'Get Started': 'Empieza',
    'Glass Mastered At': 'Masterizado en cristal en',
    'Go To Collection': 'Ir a la colección',
    'Go To Dashboard': 'Ir al escritorio',
    'Go To Edit': 'Ir a edición',
    'Go To Forum': 'Ir al foro',
    'Go To Groups': 'Ir a grupos',
    'Go To Inventory': 'Ir al inventario',
    'Go To Messages': 'Ir a mensajes',
    'Go To Orders': 'Ir a pedidos',
    'Go To Posted': 'Ir a publicados',
    'Go To Purchases': 'Ir a compras',
    'Go To Recent': 'Ir a reciente',
    'Go To Saved': 'Ir a guardado',
    'Go To Started': 'Ir a iniciado',
    'Go To Submissions': 'Ir a contribuciones',
    'Go To The Selected Release': 'Vaya a la edición seleccionada',
    'Go To User Profile': 'Ir al perfil de usuario',
    'Go To Wantlist': 'Ir a lista de deseos',
    'Go To Watched': 'Ir a visto',
    'Go to Discogs.com homepage': 'Ir a la página de inicio Discogs.com',
    'Gone.': 'No existe.',
    Groups: 'Grupos',
    'HTTP version not supported.': 'Versión de HTTP no compatible.',
    Have: 'Lo tienen',
    'Have a unique version? <0>Add a Release</0>': '¿Tiene una versión única? <0>Añada una edición</0>',
    Help: 'Ayuda',
    'Help & Support': 'Ayuda y soporte',
    'Help Is Here': 'Encuentra ayuda aquí',
    'Help Translate': 'Ayuda a traducir',
    'Help Translate Discogs': 'Ayuda a traducir el contenido de Discogs',
    'Help!': '¡Ayuda!',
    Helpful: 'Útil',
    'Here are a few lists we think deserve a mention.':
        'Aquí tienes unas cuantas listas que creemos que merece la pena mencionar.',
    'Hide Ad': 'Ocultar anuncio',
    'Hide Credits': 'Ocultar créditos',
    'Hide notes': 'Hide notes',
    'Hide replies': 'Ocultar respuestas',
    High: 'Alto',
    'High Price (0-9)': 'Precio elevado (0-9)',
    'High Price (9-0)': 'Precio elevado (9-0)',
    Highest: 'precio más alto',
    'Hip Hop': 'Hip hop',
    House: 'House',
    'Hover on an item\'s comments and click to edit, or click "remove" to remove.':
        'Pon el cursor sobre los comentarios de un artículo y haz clic en editar, o haz clic en "eliminar" para eliminar.',
    'Hover on the title or description then click to edit.':
        'Pon el cursor sobre el título o la descripción y luego haz clic en editar.',
    'How to Make a List': 'Cómo hacer una lista',
    "I'm a teapot.": 'Soy una tetera.',
    ISRC: 'ISRC',
    'If this release contains spam, help us fight it by reporting the release as spam.':
        'If this release contains spam, help us fight it by reporting the release as spam.',
    'If you wish to report other illegal activity, you are welcome to':
        'If you wish to report other illegal activity, you are welcome to',
    Image: 'Imagen',
    'Image Gallery': 'Galería de imágenes',
    'Image hidden': 'Imagen oculta',
    'Image hidden due to content': 'Imagen oculta debido a su contenido',
    Images: 'Imágenes',
    Impressum: 'Impressum',
    'In Collection': 'En colección',
    'In Groups': 'En grupos',
    'In Wantlist': 'En lista de deseos',
    'In Your Collection, Wantlist, or Inventory': 'En tu colección, lista de deseos o inventario',
    'Insufficient space on resource.': 'No hay espacio suficiente en el recurso.',
    'Insufficient storage.': 'Almacenamiento insuficiente.',
    Inventory: 'Inventario',
    Italian: 'Italiano',
    'Items I Want': 'Artículos que quiero',
    Japanese: 'Japonés',
    Jazz: 'Jazz',
    'Join In': 'Únete',
    'Keyboard Shortcuts': 'Teclas de acceso rápido',
    Korean: 'Coreano',
    Label: 'Sello',
    'Label (A-Z)': 'Sello (A-Z)',
    'Label (Z-A)': 'Sello (Z-A)',
    'Label Code': 'Código del sello',
    'Label releases bottom pagination': 'Pié de página para los títulos del sello discográfico',
    'Label releases top pagination': 'Encabezado para los títulos del sello discográfico',
    Labels: 'Sellos discográficos',
    'Labels & Companies': 'Sellos discográficos y compañías',
    'Labels or Companies': 'Sellos discográficos y Compañías',
    Labs: 'Laboratorios de Discogs',
    'Lacquer Cut At': 'Cortado en',
    'Last Sold': 'Último vendido',
    'Learn more about blocked items on Discogs': 'Más información acerca de los artículos bloqueados en Discogs',
    'Learn more about selling on Discogs': 'Más información acerca de cómo vender en Discogs',
    'Learn why': 'Averigua por qué',
    Legal: 'Aviso legal',
    'Length required.': 'Longitud requerida.',
    'Licensed From': 'Licencia de',
    'Licensed Through': 'Licencia a través de',
    'Licensed To': 'Licencia concedida a',
    Lines: 'Líneas',
    Links: 'Vínculos',
    List: 'Añadir',
    'List Explorer': 'Explorador de listas',
    'List Items For Sale': 'Poner artículos en venta',
    Lists: 'Listas',
    'Lists We Like': 'Listas que nos gustan',
    'Lists by {username}': ['Listas de ', ['username']],
    'Lists by {username} | Discogs': ['Listas de ', ['username'], ' | Discogs'],
    'Load More Reviews': 'Cargar más reseñas',
    'Loading releases': 'Cargando ediciones',
    'Loading versions': 'Cargando versiones',
    'Locked.': 'Bloqueado.',
    'Log In': 'Iniciar sesión',
    'Log Out': 'Cerrar sesión',
    'Log in to view image': 'Inicia sesión para ver la imagen',
    'Logged in as {username}': ['Sesión iniciada por ', ['username']],
    Low: 'Bajo',
    'Low Price (0-9)': 'Precio bajo (0-9)',
    'Low Price (9-0)': 'Precio bajo (9-0)',
    Lowest: 'precio más bajo',
    'Made By': 'Realizado por',
    Main: 'Principal',
    'Main Menu': 'Menú Principal',
    'Manage Collection Fields': 'Manage Collection Fields',
    'Manage Collection Folders': 'Manage Collection Folders',
    'Manage Collection Folders And Custom Fields': 'Manage Collection Folders And Custom Fields',
    'Manage Custom Fields': 'Manage Custom Fields',
    'Manage Custom Folders': 'Manage Custom Folders',
    'Manage List': 'Gestionar Lista ',
    'Manage My Lists': 'Gestionar mis listas',
    'Manage Preferences': 'Gestionar preferencias',
    'Manage videos': 'Gestionar videos',
    'Manufactured By': 'Fabricado por',
    'Manufactured For': 'Fabricado para',
    'Manufacturer Contact': 'Manufacturer Contact',
    'Manufacturer EU Contact': 'Manufacturer EU Contact',
    'Marketed By': 'Comercializado por',
    Marketplace: 'Mercado online',
    'Master Release': 'Edición maestra',
    'Mastered At': 'Masterizado en',
    'Mastering SID Code': 'Código SID de masterizado',
    'Matrix / Runout': 'Matriz/zona muerta',
    Maximum: 'Máximo',
    Med: 'Med',
    Media: 'Soporte',
    'Media condition': 'Estado del soporte',
    Median: 'Precio medio',
    'Median Price (0-9)': 'Precio medio (0-9)',
    'Median Price (9-0)': 'Precio medio (9-0)',
    Members: 'Miembros',
    'Method failure.': 'Error de método.',
    'Method not allowed.': 'Método no permitido.',
    Minimum: 'Mínimo',
    'Misdirected request.': 'Solicitud mal dirigida.',
    'Mixed At': 'Mezclado en',
    'Monthly Leaderboards': 'Clasificaciones mensuales',
    'More images': 'Más imágenes',
    'More results...': 'Más resultados…',
    'Mould SID Code': 'Código SID de molde',
    'Move Selection Down': 'Mover selección hacia abajo',
    'Move Selection Up': 'Mover selección hacia arriba',
    'Move item to:': 'Mover artículo a:',
    'Move items to:': 'Mover artículos a:',
    'Move to Folder...': 'Move to Folder...',
    'Move to position...': 'Mover a la posición...',
    'Moved to folder': 'Movido a la carpeta',
    'My Store': 'Mi Tienda',
    'My Wantlist | Discogs': 'Mi lista de deseos | Discogs',
    'Navigate to {title}': ['Ir a ', ['title']],
    Navigation: 'Navegación',
    'Needs Changes': 'Necesita Cambios',
    'Needs Vote': 'Necesita votación',
    'Needs major changes': 'Necesita cambios importantes',
    'Needs minor changes': 'Necesita cambios menores',
    Never: 'Nunca',
    'New List': 'Nueva lista',
    'New Submission': 'Nueva Contribución',
    'Newest Addition': 'Incorporación más reciente',
    Next: 'Siguiente',
    'Next image': 'Siguiente imagen',
    'Next<0/>': 'Siguiente<0/>',
    'No image available': 'Sin imagen disponible',
    'No image available; add an image': 'Sin imagen disponible, añada una imagen',
    'No items available in the Marketplace': 'No hay ningún artículo disponible en el mercado online',
    'No items found.': 'No se encontraron artículos.',
    'No items found. Try different keywords or check your spelling.':
        'No items found. Try different keywords or check your spelling.',
    'No versions could be found using these filters.': 'No se encontraron versiones con estos filtros.',
    'Not acceptable.': 'No aceptable.',
    'Not implemented.': 'No implementado.',
    Notes: 'Notas',
    'Nothing in collection.': 'Nada en colección.',
    Notifications: 'Notificaciones',
    'Notify me of new submissions related to this list.':
        'Avísame de nuevas contribuciones relacionadas con este artículo.',
    OK: 'De acuerdo',
    Okay: 'De acuerdo',
    'Oldest Addition': 'Incorporación más antigua',
    'On The Go': 'En proceso',
    'Only items in violation of': 'Only items in violation of',
    'Oops! Could not file report, please submit a support ticket.':
        'Oops! Could not file report, please submit a support ticket.',
    Optional: 'Opcional',
    Options: 'Opciones',
    Orders: 'Pedidos',
    Other: 'Otro',
    'Other Versions': 'Otras versiones',
    'Other Versions ({0} of {total})': ['Otras versiones (', ['0'], ' de ', ['total'], ')'],
    'Other Versions ({0})': ['Otras versiones (', ['0'], ')'],
    'Overdubbed At': 'Sobregrabado en',
    'Parent Label': 'Sello matriz',
    Pause: 'Pausa',
    'Payment required.': 'Se requiere pago.',
    Permalink: 'Enlace permanente',
    'Phonographic Copyright ℗': 'Copyright fonográfico ℗',
    Play: 'Reproducir',
    'Play {title}': ['Reproducir ', ['title']],
    Pop: 'Pop',
    'Pop Rock': 'Pop rock',
    Portuguese: 'Portugués',
    Position: 'Posición',
    'Precondition failed.': 'Error de condición previa.',
    'Precondition required.': 'Se requiere condición previa.',
    'Pressed By': 'Prensado por',
    'Pressing Plant ID': 'ID de fábrica de impresión',
    Prev: 'Prev.',
    Preview: 'Vista previa',
    Previous: 'Anterior',
    'Previous image': 'Imagen anterior',
    'Price Code': 'Código de precio',
    'Printed By': 'Impreso por',
    Privacy: 'Privacidad',
    'Privacy Policy': 'Política de privacidad',
    'Produced At': 'Producido en',
    'Produced For': 'Producido para',
    Profile: 'Perfil',
    'Prog Rock': 'Prog rock',
    'Proxy authentication required.': 'Se requiere autenticación del proxy.',
    Public: 'Público',
    'Published By': 'Publicado por',
    Punk: 'Punk',
    Purchases: 'Compras',
    'Quote Previous': 'Citar anterior',
    'Random Item': 'Random Item',
    Rating: 'Valoración',
    'Rating (0-9)': 'Valoración (0-9)',
    'Rating (9-0)': 'Valoración (9-0)',
    Ratings: 'Valoraciones',
    'Read More': 'Leer más',
    'Read More About Creating Lists': 'Más información acerca de cómo crear listas',
    'Real Name': 'Nombre real',
    'Recent Lists': 'Listas recientes',
    'Recently Created Lists | Discogs Lists': 'Listas creadas recientemente | Listas de Discogs',
    'Recently Edited': 'Recientemente editado',
    'Recently Used': 'Usadas recientemente',
    Recommendations: 'Recomendaciones',
    'Record Company': 'Discográfica',
    'Record Stores': 'Tiendas de Discos',
    'Recorded At': 'Grabado en',
    'Recorded By': 'Grabado por',
    'Reel-To-Reel': 'Cinta de bobina',
    Register: 'Registrarse',
    'Related Videos': 'Videos Relacionados',
    Release: 'Publicación',
    Released: 'Publicado',
    Releases: 'Ediciones',
    'Remastered At': 'Remasterizado en',
    'Remixed At': 'Remezclado en',
    Remove: 'Eliminar',
    'Remove Item': 'Eliminar Artículo',
    'Remove Items': 'Eliminar artículos',
    'Remove Rating': 'Eliminar valoración',
    'Remove Selected Release from Wantlist': 'Suprima la edición seleccionada de lista de deseos',
    'Remove all versions from Wantlist': 'Suprima todas las versiones de lista de deseos',
    'Remove from Collection': 'Eliminar de la colección',
    'Remove from Wantlist': 'Eliminar de Deseados',
    Removed: 'Eliminado',
    'Removed Videos': 'Vídeos eliminados',
    'Removed video': 'Video Eliminado',
    Rename: 'Renombrar',
    Reply: 'Responder',
    Report: 'Denunciar',
    'Report Offensive Material': 'Report Offensive Material',
    'Report Release': 'Report Release',
    'Report Spam': 'Report Spam',
    'Report Suspicious Activity': 'Reporte Actividad Sospechosa',
    'Request entity too large.': 'Entidad de solicitud demasiado grande.',
    'Request header fields too large.': 'Campos del encabezado de solicitud demasiado grandes.',
    'Request timeout.': 'Se agotó el tiempo de espera de la solicitud.',
    'Request-URI too long.': 'URI de solicitud demasiado grande.',
    'Requested range not satisfiable.': 'No se puede satisfacer el intervalo solicitado.',
    'Revert to the old version.': 'Revertir a la versión anterior.',
    Reviews: 'Críticas',
    'Rights Society': 'Entidad de gestión colectiva',
    Rock: 'Rock',
    Russian: 'Ruso',
    'SPARS Code': 'Código SPARS',
    Save: 'Guardar',
    'Save Changes': 'Guardar cambios',
    'Save Fields': 'Save Fields',
    'Saved your changes': 'Se han guardado los cambios',
    'Saved your changes.': 'Se guardaron tus cambios.',
    'Saving...': 'Guardando…',
    'Scroll left': 'Deslizar a la izquierda',
    'Scroll right': 'Deslizar a la derecha',
    Search: 'Buscar',
    'Search & Filters': 'Búsqueda y filtros',
    'Search Collection': 'Buscar en la colección',
    'Search Discography': 'Buscar en discografía',
    'Search Discogs': 'Buscar Discogs',
    'Search Lists': 'Buscar en listas',
    'Search artists, albums and more...': 'Buscar artistas, álbumes y otros...',
    'Search barcodes and other identifiers...': 'Buscar códigos de barras y otros identificadores…',
    'Search suggestions': 'Busque sugerencias',
    'Search...': 'Buscar…',
    'See all versions': 'Ver todas las versiones',
    'Select an item': 'Selecciona un artículo',
    'Select {0} with {1} {2}': ['Elección ', ['0'], ' con', ['1'], ['2']],
    'Sell All Media': 'Vender todos los medios',
    'Sell Betacam-SP': 'Vender Betacam-SP',
    'Sell Blu-ray': 'Vender Blu-ray',
    'Sell Box Set': 'Vender cofre',
    'Sell CD': 'Vender CD',
    'Sell CDr': 'Vender CDr',
    'Sell Cassette': 'Vender casete',
    'Sell DVD': 'Vender DVD',
    'Sell File': 'Vender archivo',
    'Sell Music': 'Vender música',
    'Sell Reel-To-Reel': 'Vender cinta de bobina',
    'Sell U-Matic': 'Vender U-matic',
    'Sell VHS': 'Vender VHS',
    'Sell Vinyl': 'Vender vinilo',
    'Sell a Copy': 'Venda una Copia',
    'Sell a copy': 'Venda una copia',
    Seller: 'Vendedor',
    'Send a message': 'Enviar un mensaje',
    Series: 'Serie',
    'Service unavailable.': 'Servicio no disponible.',
    Settings: 'Configuración',
    Share: 'Compartir',
    Shop: 'Tienda',
    'Shop now': 'Comprar ahora',
    'Shop this version': 'Compre esta versión',
    Show: 'Mostrar',
    'Show Credits': 'Mostrar Créditos',
    'Show Shortcut Help': 'Mostrar acceso directo a ayuda',
    'Show all {0} tracks': ['Mostrar las ', ['0'], ' pistas'],
    'Show all {totalCount} versions': ['Mostrar todas ', ['totalCount'], 'las versiones'],
    'Show images of {0}, {1}, {2}': ['Mostrar imágenes de ', ['0'], ', ', ['1'], ', ', ['2']],
    'Show less': 'Mostrar menos',
    'Show more': 'Mostrar más',
    'Show more credits...': 'Muestre mas Créditos...',
    'Show notes': 'Show notes',
    'Showing {0}-{1} of {2}': ['Mostrando ', ['0'], '-', ['1'], ' de ', ['2']],
    'Showing {0}-{adjustedCount} of {adjustedTotalCount}': [
        'Mostrando ',
        ['0'],
        '-',
        ['adjustedCount'],
        ' de ',
        ['adjustedTotalCount'],
    ],
    Sites: 'Sítios',
    'Sleeve condition': 'Condición de la funda',
    'Something went wrong': 'Algo no ha ido bien',
    'Something went wrong adding your review': 'Se ha producido un error al añadir tu crítica',
    'Something went wrong in this section of the page.': 'Se ha producido un error en esta sección de la página.',
    'Something went wrong in this section.': 'Se ha producido un error en esta sección',
    'Something went wrong please try again': 'Something went wrong please try again',
    'Something went wrong.': 'Algo no ha ido bien.',
    'Sorry, this Artist does not have any Releases under that name.':
        'Lo sentimos, pero este artista no tiene ninguna edición con ese nombre.',
    'Sorry, this Label does not have any Releases under that name.':
        'Lo sentimos, pero este sello discográfico no tiene ninguna edición con ese nombre.',
    Sort: 'Clasificar',
    Soul: 'Soul',
    Spanish: 'Español',
    'Start Selling': 'Empieza a vender',
    Statistics: 'Estadística',
    Status: 'Estado',
    'Stop impersonating': 'Cese de suplantar',
    'Stop impersonating {0}': ['Cese de suplantar ', ['0']],
    Style: 'Estilo',
    Styles: 'Estilos',
    Sublabels: 'Subsellos',
    'Submission Guidelines': 'Normas para contribuir',
    Submissions: 'Contribuciones',
    Submit: 'Envíar',
    'Submit Spam Report': 'Submit Spam Report',
    'Submit a Release': 'Contribuir con una edición',
    'Switch to text-only view': 'Cambiar a la vista de solo texto',
    'Synth-Pop': 'Technopop',
    'Terms of Service': 'Condiciones del servicio',
    'Text area': 'Text area',
    'Text-only view': 'Vista de solo texto',
    'Thanks, your report was received.': 'Thanks, your report was received.',
    'That artist does not exist or may have been deleted.': 'Ese artista no existe o puede que haya sido eliminado.',
    'That label does not exist or may have been deleted.': 'Ese sello discográfico no existe o pudo ser eliminado',
    'That list does not exist or may have been deleted.': 'Esa lista no existe o pudo ser eliminada.',
    'That release does not exist or may have been deleted.': 'Esa edición no existe o puede que haya sido eliminada.',
    "The Artist {0} is invalid. Please refer to this Artist instead. View <0>{1}'s edit history</0>.": [
        'El artista',
        ['0'],
        'es incorrecto. Por favor refiera a este artista en sustitución de. Vea<0>Historial de ediciones de',
        ['1'],
        '</0>.',
    ],
    "The Label {0} is invalid. Please refer to this Label instead. View <0>{1}'s edit history</0>.": [
        'El sello ',
        ['0'],
        ' no es válido. Consulta este otro sello en su lugar. <0>Historial de ediciones de ',
        ['1'],
        '</0>.',
    ],
    'The value "{id}" is not a valid Discogs identifier.': [
        'El valor "',
        ['id'],
        '" no es un identificador válido de Discogs.',
    ],
    'There are no videos here': 'No hay ningún vídeo aquí',
    'This artist does not exist or may have been deleted.': 'Este artista no existe o pudo ser eliminado.',
    'This artist is used as a placeholder entry and does not link to any artist.':
        'Este artista se utiliza como una entrada de marcador de posición y no se vincula a ningún artista.',
    'This master release does not exist or may have been deleted.':
        'Esta Master-Release no existe o pudo ser eliminada.',
    'This release is blocked from sale on Discogs. It is not permitted to sell this item in our Marketplace.':
        'No se permite la venta de esta edición en Discogs. En otras palabras, no es posible vender este artículo en nuestro mercado online.',
    'Thumbnail image': 'Imagen de la miniatura',
    Title: 'Título',
    'Title (A-Z)': 'Título (A-Z)',
    'Title (Z-A)': 'Título (Z-A)',
    'Toggle Community Menu': 'Alternar menú de la comunidad',
    'Toggle Explore Menu': 'Mostrar/ocultar el menú Explorar',
    'Toggle Marketplace Menu': 'Cambiar el menú del mercado online',
    'Toggle Zoom': 'Alternar zoom',
    'Toggle section': 'Mostrar/ocultar sección',
    'Too many requests.': 'Demasiadas solicitudes.',
    'Top pagination': 'Paginación superior',
    Tracklist: 'Lista de Títulos',
    'Tracks {0} — {1} of {2}': ['Pistas ', ['0'], '  — ', ['1'], ' de ', ['2']],
    Twitter: 'Twitter',
    Type: 'Tipo',
    'U-Matic': 'U-matic',
    'Unauthorized.': 'No autorizado.',
    'Unavailable due to legal reasons.': 'No disponible por motivos legales.',
    'Undo changes': 'Deshacer cambios',
    Unknown: 'Desconocido',
    'Unprocessable entity.': 'Entidad no procesable.',
    'Unsupported media type.': 'Tipo de medio no compatible.',
    'Use drag and drop to re-order the items in your list, or click "move to," type a new position, and press enter.':
        'Puedes arrastrar y soltar para cambiar el orden de los artículos de tu lista, o clicar en "mover a", escribir una nueva posición, y pulsar Enter.',
    User: 'Usuario',
    'User Menu': 'Menú del usuario',
    VHS: 'VHS',
    Variations: 'Variaciones',
    'Version Details': 'Detalles de la versión',
    Versions: 'Versiones',
    'Video Player': 'Reproductor de vídeo',
    'Video Search': 'Búsqueda de vídeos',
    Videos: 'Videos',
    'Videos from related releases, artists, or labels.': 'Videos de ediciones, artistas o sellos relacionados.',
    'View All': 'Ver Todos',
    'View Help': 'Ver ayuda',
    'View Images for Selected Release': 'Vea Imágenes de la edición Seleccionada',
    'View More {name}': ['Ver mas ', ['name']],
    'View Submissions': 'Ver contribuciones',
    'View all of my lists': 'Ver todas mis listas',
    'View credits, reviews, tracks and shop for the {info} release of "{title}" on Discogs.': [
        'Consulta los créditos, las críticas y las canciones, y compra la edición de ',
        ['info'],
        ' de "',
        ['title'],
        '" en Discogs.',
    ],
    'View in admin': 'Ver en administrador',
    Viewing: 'Viendo',
    'Viewing All': 'Verlas todas',
    Vinyl: 'Vinilo',
    'Visit Wantlister': 'Visitar Wantlister',
    Want: 'Lo quieren',
    'Want to see other lists from the Discogs Community? Check out <0>Recent Lists.</0>':
        '¿Te apetece ver otras listas de la comunidad de Discogs? Echa un vistazo entonces a las <0>listas recientes.</0>',
    'Want to see other lists from the Discogs Community? Check out <0>Recent Lists</0>.':
        '¿Desea ver otras listas de la Comunidad Discogs? Revise <0>Listas Recientes</0>.',
    Wanted: 'Deseados',
    Wantlist: 'Lista de deseos',
    'We could not find that page.': 'No pudimos encontrar esa página.',
    Year: 'Año',
    'Year (0-9)': 'Año (0-9)',
    'Year (9-0)': 'Año (9-0)',
    'You are currently viewing the new version of the release page.':
        'Estás viendo la nueva versión de la página de publicación.',
    'You are impersonating {0}.': ['Usted está suplantando ', ['0'], '.'],
    'You are viewing the new version of the Master Page.': 'Usted ve una nueva versión de la página principal.',
    'You can update your default language preference in your <0>General Settings</0>.':
        'Puedes modificar tu idioma por defecto preferido en tu <0>Configuración General</0>.',
    'You clicked on the {from} version of this page.': [
        'Has hecho clic en la versión en ',
        ['from'],
        ' de esta página.',
    ],
    'You do not have any custom note fields for your collection. Create some by clicking Add a field':
        'Usted no tiene para su colección ningun campo de notas. Genere una haciendo click en Añadir campo',
    'You need to be logged in to see this page.': 'Tienes que iniciar sesión para ver esta página.',
    'YouTube search query:': 'Consulta de búsqueda en YouTube',
    'Zoom In': 'Acercar',
    'Zoom Out': 'Alejar',
    from: 'de',
    'no rating': 'sin valoración',
    'not rated': 'no valorado',
    'our information on copyright infringement': 'our information on copyright infringement',
    referencing: 'comentando',
    remove: 'eliminar',
    'submit a support request': 'submit a support request',
    'tracks: {0}': ['pistas: ', ['0']],
    'updated just now': 'Actualizar ahora',
    'will be reviewed for potential marketplace blocks. If the item is deemed in violation of our offensive materials policy, it will be blocked from sale in the marketplace, but will remain in the Database. If you have a concern about copyright, please see':
        'will be reviewed for potential marketplace blocks. If the item is deemed in violation of our offensive materials policy, it will be blocked from sale in the marketplace, but will remain in the Database. If you have a concern about copyright, please see',
    '{0, plural, one {# copy} other {# copies}}': [
        ['0', 'plural', { one: ['#', ' copia'], many: ['#', ' copias'], other: ['#', ' copias'] }],
    ],
    '{0, plural, one {+# more label...} other {+# more labels...}}': [
        [
            '0',
            'plural',
            {
                one: ['+', '#', ' etiqueta más…'],
                many: ['+', '#', ' etiquetas más…'],
                other: ['+', '#', ' etiquetas más…'],
            },
        ],
    ],
    '{0, plural, one {See # reply} other {See # replies}}': [
        [
            '0',
            'plural',
            {
                one: ['Ver ', '#', ' respuesta'],
                many: ['Ver ', '#', ' respuestas'],
                other: ['Ver ', '#', ' respuestas'],
            },
        ],
    ],
    '{0, plural, one {rated this release 1 star} other {rated this release # stars}}': [
        [
            '0',
            'plural',
            {
                one: 'ha valorado esta edición con 1 estrella',
                many: ['ha valorado esta edición con ', '#', ' estrellas'],
                other: ['ha valorado esta edición con ', '#', ' estrellas'],
            },
        ],
    ],
    '{0}': [['0']],
    '{0} from {1}': [['0'], ' de ', ['1']],
    '{0} in Inventory': [['0'], ' en el inventario'],
    "{0}'s avatar": ['Avatar de ', ['0']],
    '{artistName} - {title} album cover': ['Portada de album ', ['artistName'], ' - ', ['title']],
    '{copiesText} for sale': [['copiesText'], ' en venta'],
    '{count, plural, one {# unread message} other {# unread messages}}': [
        [
            'count',
            'plural',
            {
                one: ['#', ' mensaje no leído'],
                many: ['#', ' mensajes no leídos'],
                other: ['#', ' mensajes no leídos'],
            },
        ],
    ],
    '{count} in Collection': [['count'], ' en la colección'],
    '{count} in Inventory': [['count'], ' en el inventario'],
    '{count} in Wantlist': [['count'], ' en la lista de deseos'],
    '{count} in cart': [['count'], ' en el carrito'],
    '{count} results are available, use up and down arrow keys to navigate.': [
        'Hay ',
        ['count'],
        ' resultados disponibles; utiliza las teclas de dirección arriba y abajo para navegar.',
    ],
    '{days, plural, one {Added # day ago} other {Added # days ago}}': [
        [
            'days',
            'plural',
            {
                one: ['Añadido hace ', '#', ' día'],
                many: ['Añadido hace ', '#', ' días'],
                other: ['Añadido hace ', '#', ' días'],
            },
        ],
    ],
    '{days, plural, one {Edited one day ago} other {Edited # days ago}}': [
        [
            'days',
            'plural',
            {
                one: 'Editado hace un día',
                many: ['Editado hace ', '#', ' días'],
                other: ['Editado hace ', '#', ' días'],
            },
        ],
    ],
    '{days, plural, one {updated # day ago} other {updated # days ago}}': [
        [
            'days',
            'plural',
            {
                one: ['actualizado hace ', '#', ' día'],
                many: ['actualizado hace ', '#', ' días'],
                other: ['actualizado hace ', '#', ' días'],
            },
        ],
    ],
    '{heading}': [['heading']],
    '{hours, plural, one {Added # hour ago} other {Added # hours ago}}': [
        [
            'hours',
            'plural',
            {
                one: ['Añadido hace ', '#', ' hora'],
                many: ['Añadido hace ', '#', ' horas'],
                other: ['Añadido hace ', '#', ' horas'],
            },
        ],
    ],
    '{hours, plural, one {Edited one hour ago} other {Edited # hours ago}}': [
        [
            'hours',
            'plural',
            {
                one: 'Editado hace una hora',
                many: ['Editado hace ', '#', ' horas'],
                other: ['Editado hace ', '#', ' horas'],
            },
        ],
    ],
    '{hours, plural, one {updated # hour ago} other {updated # hours ago}}': [
        [
            'hours',
            'plural',
            {
                one: ['actualizado hace ', '#', ' hora'],
                many: ['actualizado hace ', '#', ' horas'],
                other: ['actualizado hace ', '#', ' horas'],
            },
        ],
    ],
    '{key}': [['key']],
    '{min, plural, one {Rate this release 1 star} other {Rate this release # stars}}': [
        [
            'min',
            'plural',
            {
                one: 'Valora esta edición con 1 estrella',
                many: ['Valora esta edición con ', '#', ' estrellas'],
                other: ['Valora esta edición con ', '#', ' estrellas'],
            },
        ],
    ],
    '{minutes, plural, one {Added # minute ago} other {Added # minutes ago}}': [
        [
            'minutes',
            'plural',
            {
                one: ['Añadido hace ', '#', ' minuto'],
                many: ['Añadido hace ', '#', ' minutos'],
                other: ['Añadido hace ', '#', ' minutos'],
            },
        ],
    ],
    '{minutes, plural, one {Edited one minute ago} other {Edited # minutes ago}}': [
        [
            'minutes',
            'plural',
            {
                one: 'Editado hace un minuto',
                many: ['Editado hace ', '#', ' minutos'],
                other: ['Editado hace ', '#', ' minutos'],
            },
        ],
    ],
    '{minutes, plural, one {updated # minute ago} other {updated # minutes ago}}': [
        [
            'minutes',
            'plural',
            {
                one: ['actualizado hace ', '#', ' minuto'],
                many: ['actualizado hace ', '#', ' minutos'],
                other: ['actualizado hace ', '#', ' minutos'],
            },
        ],
    ],
    '{months, plural, one {Added # month ago} other {Added # months ago}}': [
        [
            'months',
            'plural',
            {
                one: ['Añadido hace ', '#', ' mes'],
                many: ['Añadido hace ', '#', ' meses'],
                other: ['Añadido hace ', '#', ' meses'],
            },
        ],
    ],
    '{months, plural, one {Edited one month ago} other {Edited # months ago}}': [
        [
            'months',
            'plural',
            {
                one: 'Editado hace un mes',
                many: ['Editado hace ', '#', ' meses'],
                other: ['Editado hace ', '#', ' meses'],
            },
        ],
    ],
    '{months, plural, one {updated # month ago} other {updated # months ago}}': [
        [
            'months',
            'plural',
            {
                one: ['actualizado hace ', '#', ' mes'],
                many: ['actualizado hace ', '#', ' meses'],
                other: ['actualizado hace ', '#', ' meses'],
            },
        ],
    ],
    '{name}': [['name']],
    '{name} Discography | Discogs': ['Discografía | Discogs de ', ['name']],
    '{name} by {username} | Discogs Lists': ['Listas Discogs | ', ['name'], 'por ', ['username']],
    '{name} image': ['imágen de ', ['name']],
    '{name} | Discogs': [['name'], ' | Discogs'],
    '{name}, Primary, {0} of {1}': [['name'], ', principal, ', ['0'], ' de ', ['1']],
    '{name}, Secondary, {0} of {1}': [['name'], ', secundario, ', ['0'], ' de ', ['1']],
    '{num} of {total} results': ['Resultado ', ['num'], ' de ', ['total']],
    '{pendingSubmissionsCount} submissions pending': [['pendingSubmissionsCount'], 'envíos pendientes'],
    '{referenceType}s': [['referenceType'], 's'],
    '{title}': [['title']],
    '{totalFiltersApplied} Applied': [['totalFiltersApplied'], 'Aplicados'],
    '{total} versions': [['total'], ' versiones'],
    "{username}'s avatar": ['Avatar de ', ['username']],
    '{value}': [['value']],
    '{versionCount, plural, one {# credit} other {# credits}}': [
        ['versionCount', 'plural', { one: ['#', ' crédito'], many: ['#', ' créditos'], other: ['#', ' créditos'] }],
    ],
    '{versionCount, plural, one {# version} other {# versions}}': [
        ['versionCount', 'plural', { one: ['#', ' versión'], many: ['#', ' versiones'], other: ['#', ' versiones'] }],
    ],
    '{years, plural, one {Added # year ago} other {Added # years ago}}': [
        [
            'years',
            'plural',
            {
                one: ['Añadido hace ', '#', ' año'],
                many: ['Añadido hace ', '#', ' años'],
                other: ['Añadido hace ', '#', ' años'],
            },
        ],
    ],
    '{years, plural, one {Edited one year ago} other {Edited # years ago}}': [
        [
            'years',
            'plural',
            {
                one: 'Editado hace un año',
                many: ['Editado hace ', '#', ' años'],
                other: ['Editado hace ', '#', ' años'],
            },
        ],
    ],
    '{years, plural, one {updated # year ago} other {updated # years ago}}': [
        [
            'years',
            'plural',
            {
                one: ['actualizado hace ', '#', ' año'],
                many: ['actualizado hace ', '#', ' años'],
                other: ['actualizado hace ', '#', ' años'],
            },
        ],
    ],
}
